import {post, get} from "@/utils/request.js";


/**
 * 城市列表
 * showApartments
 * @returns
 * @param data
 */
export const getCitiesAPI = (data) => get(`/api/publics/cities`, data).then(res => {
    let width = screen.width;
    let slug = '?x-oss-process=image/resize,w_' + width;
    let data = res.data.data;
    data.forEach((element, index) => {
        element.thumbnailUrl = element.thumbnailUrl + slug;
        data[index] = element;
    });
    res.data.data = data;
    return res;
});

/**
 * FAQ列表
 * cityId
 * apartmentId
 * @returns {*}
 */
export const getFaqsAPI = (data) => get(`/api/publics/faqs`, data);

/**
 * 城市详情
 * @returns {*}
 */
export const getCityAPI = (slug) => get(`/api/web/cities/${slug}`).then(res => {
    let width = screen.width;
    let slug = '?x-oss-process=image/resize,w_' + width;
    let data = res.data.data;
    let apartments = data.apartments;
    apartments.forEach((element, index) => {
        let pcImgs = element.pcImgs;
        pcImgs.forEach((img, index) => {
            img.thumbnail = img.thumbnail + slug;
            pcImgs[index] = img;
        })
        element.pcImgs = pcImgs;
        apartments[index] = element;
    });
    data.apartments = apartments;

    let universities = data.universities;
    universities.forEach((university, index) => {
        university.thumbnailUrl = university.thumbnailUrl + slug;
        universities[index] = university;
    });
    data.universities = universities;
    res.data.data = data;
    return res;
});;

/**
 * 公寓详情
 * @returns {*}
 */
export const getApartmentAPI = (slug, apartmentSlug) => get(`/api/web/cities/${slug}/${apartmentSlug}`).then(res => {
    let width = screen.width;
    let slug = '?x-oss-process=image/resize,w_' + width;
    let data = res.data.data;
    let pcImgs = data.pcImgs;
   
    pcImgs.forEach((img, index) => {
        img.thumbnail = img.thumbnail + slug;
        pcImgs[index] = img;
    })
    data.pcImgs = pcImgs;

    let roomTypes = data.roomTypes;
    roomTypes.forEach((roomType, index) => {
        let pictures = roomType.pictures;
        pictures.forEach((picture, index) => {
            picture.img = picture.img + slug;
            pictures[index] = picture;
        })
        roomTypes[index] = roomType;
    })
    data.roomTypes = roomTypes;
    
    res.data.data = data;
    return res;
});

export const submitConsultsAPI = (data) => post(`/api/publics/consults-web`, data);

export const getServicesAPI = (data) => get(`/api/publics/services`, data);

export const getApartmentsAPI = (data) => get(`/api/publics/apartments`, data);

export const getKeywordsAPI = (data) => get(`/api/publics/keywords`, data);


