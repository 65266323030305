export const common = require('./cn/common.json')
export const header = require('./cn/header.json')
export const home = require('./cn/home.json')
export const footer = require('./cn/footer.json')
export const blog = require('./cn/blog.json')
export const cities = require('./cn/cities.json')
export const apartment = require('./cn/apartment.json')
export const city = require('./cn/city.json')
export const contactUs = require('./cn/contact-us.json')
export const faqs = require('./cn/faqs.json')
export const parents = require('./cn/parents.json')
export const lifeAtVita = require('./cn/life-at-vita.json')
export const internationalStudents = require('./cn/international-students.json')