<template>
    <div id="app">
        <Index/>
    </div>
</template>

<script>
import Index from './views/layout/index.vue'

export default {
    name: 'App',
    components: {
        Index
    },
    beforeCreate() {
        if (
            (navigator.userAgent.match(/Mobi/i) ||
            navigator.userAgent.match(/Android/i) ||
            navigator.userAgent.match(/iPhone/i)) &&
            !window.location.href.match(/mobile/i)
           
        ) {
            window.location.href = '/cn/mobile';
        }
    },
    created() {
        document.body.style.overflowX = 'hidden';
    },
    methods: {}

}
</script>

<style lang="less">
@import "../src/assets/less/font.less";
@import "../src/assets/less/index.less";
@import "/src/assets/less/base.less";
@import "/src/assets/less/common.less";

#app {
    width: 100%;
    font-family: Radikal Light, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

}

.container {
    margin-top: 65px;
    padding: 30px;
}

body {
    overflow-x: hidden;
}


.redbook .el-dialog__body, .redbook .el-dialog__header {
	padding: 0px !important;
}

</style>
