<template>
    <div class="container-fluid" style="padding: 60px;" :class="colorType === 0 ?'color-black' : 'color-white'">
        <div class="footer-top" v-if="showCities">
            <div class="footer-top-left" v-html="$t('footer.beAPartOfIt')"></div>
            <div class="footer-top-right" style="width: 30.8%">
                <div class="title cursor">
                    <div class="label-name text-trade p-t-3 font-size-26"
                         style="line-height: 1.2"
                         :class="colorType === 0 ?'label-name-white' : 'label-name-black'"
                         @click="$goto(`/cities`)">{{ $t('footer.cities') }}
                    </div>
                </div>
                <br>
                <div class="row mt-5" style="font-size: 16px" v-if="cityList && cityList.length > 0">
                    <div class="col-6 mb-3" v-for="(city, index) of cityList" :key="index">
                        <div class="label-name cursor"
                             :class="colorType === 0 ?'label-name-white' : 'label-name-black'"
                             @click="$goto(`/cities/${city.slug}`)"> {{ city.name }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer-top-right" style="width: 20%">
                <div class="title cursor">
                    <div class="label-name text-trade p-t-3 font-size-26"
                         style="line-height: 1.2"
                         :class="colorType === 0 ?'label-name-white' : 'label-name-black'"
                         @click="$goto(`/contact-us`)">{{ $t('footer.getInTouch') }}
                    </div>
                </div>
                <br>
                <div class="mt-5" style="font-size: 16px">
                    <div class="text-uppercase">{{ $t('footer.followUs') }}</div>
                    <div v-if="colorType === 0">
                        <div class="icons">
                            <img class="img-icon cursor" @click="handelDialog(1)" :src="require('assets/images/icon/wechat.jpg')" alt="">
                            <img class="img-icon cursor" @click="handelDialog(2)" :src="require('assets/images/icon/littleRedBook.jpg')" alt="">
                            <img class="img-icon cursor" @click="handelDialog(3)" :src="require('assets/images/icon/tiktok.jpg')" alt="">
                        </div>
                        <div class="icons">
                            <img class="img-icon cursor" @click="handelDialog(4)" :src="require('assets/images/icon/bilibili.jpg')" alt="">
                            <img class="img-icon cursor" @click="handelDialog(5)" :src="require('assets/images/icon/weibo.jpg')" alt="">
                            <img class="img-icon cursor" @click="handelDialog(6)" :src="require('assets/images/icon/ins.jpg')" alt="">
                        </div>
                    </div>

                    <div  v-if="colorType === 1">
                        <div class="icons">
                            <img class="img-icon cursor" @click="handelDialog(1)" :src="require('assets/images/icon/wechat_white.jpg')" alt="">
                            <img class="img-icon cursor" @click="handelDialog(2)" :src="require('assets/images/icon/littleRedBook_white.jpg')" alt="">
                            <img class="img-icon cursor" @click="handelDialog(3)" :src="require('assets/images/icon/tiktok_white.jpg')" alt="">
                        </div>
                        <div class="icons">
                            <img class="img-icon cursor" @click="handelDialog(4)" :src="require('assets/images/icon/bilibili_white.jpg')" alt="">
                            <img class="img-icon cursor" @click="handelDialog(5)" :src="require('assets/images/icon/weibo_white.jpg')" alt="">
                            <img class="img-icon cursor" @click="handelDialog(6)" :src="require('assets/images/icon/ins_white.jpg')" alt="">
                        </div>
                    </div>
                </div>
                <el-dialog width="400px" :title="imgObj.title" :visible.sync="dialogVisibleImg">
                    <div class="dialog-body text-center">
                        <img width="260" height="260" :src="imgObj.url" alt="">
                    </div>
                    <div slot="footer" class="dialog-footer"></div>
                </el-dialog>
            </div>
        </div>

        <div class="footer-bottom" :class="colorType === 0 ?'bg-black' : 'bg-grey'">
            <div class="footer-top-left">
                <div>VITA STUDENT</div>
                <div class="d-flex align-items-center link-name">
                    <span class="font-size-14">联系我们</span>
                    <span class="font-size-12 m-l-10">+44 203 096 1717</span>
                    <span class="font-size-12 m-l-10">hello@vitastudent.com</span>
                </div>
            </div>
            <div class="footer-top-right">
                <div>隐私保护条例</div>
                <div class="m-t-10">维塔学舍房地产经纪(上海)有限公司</div>
                <div>英国高端体验式学生公寓</div>
                <div>沪ICP备16053477号</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "footers",
    data() {
        return {
            diaLogType: 1,
            dialogVisibleImg: false
        }
    },
    props: {
        colorType: {
            type: Number,
            default: 0
        },
        cities: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        imgObj() {
            let type = this.diaLogType
            if (type === 1) {
                return {
                    title: this.$t('home.wechat'),
                    url: require('assets/images/icon/wechat.png')
                };
            }
            if (type === 2) {
                return {
                    title: this.$t('home.littleRedBook'),
                    url: require('assets/images/icon/littleRedBook.png')
                };
            }
            if (type === 3) {
                return {
                    title: this.$t('home.tiktok'),
                    url: require('assets/images/icon/tiktok.png')
                };
            }
            if (type === 4) {
                return {
                    title: this.$t('home.bilibili'),
                    url: require('assets/images/icon/bilibili.png')
                };
            }
            if (type === 5) {
                return {
                    title: this.$t('home.weibo'),
                    url: require('assets/images/icon/weibo.png')
                };
            }
            if (type === 6) {
                return {
                    title: this.$t('home.ins'),
                    url: require('assets/images/icon/ins.png')
                };
            }
            return {
                title: '',
                url: ''
            };
        },
        color: function () {
            if (this.colorType === 0) return [this.colorArr[0], this.colorArr[1]];
            else return [this.colorArr[1], this.colorArr[0]];
        },
        cityList() {
            let isCn = this.$i18n.locale === 'cn'
            const cities = []
            this.cities.forEach(rest => {
                let city = {
                    id: rest.id,
                    img: null,
                    slug: rest.slug,
                    name: isCn ? rest.name : rest.enName,
                    country: isCn ? rest.country : rest.enCountry
                };
                cities.push(city)
            })
            return cities
        },
        showCities() {
            let slug = this.$route.path;
            if(slug.includes('find-your-room')) {
                return false;
            }
            return true;
        }
    },
    methods: {
        handelDialog(type) {
            this.diaLogType = type
            this.dialogVisibleImg = true
        },
        handleSelect(key, keyPath) {
            this.$router.push(keyPath[0]);
        },
    }
}
</script>

<style lang="less" scoped>

.footer-top {
    text-align: left;
    display: flex;
    justify-content: end;
    font-size: 18px;

    .footer-top-left {
        font-size: 105px;
        font-weight: 700;
        font-family: Trade Gothic LT Std Condensed, sans-serif;
        margin-right: auto;
        line-height: 1;
    }

    .footer-top-right {
        padding: 0 30px;
        width: 350px;
        border-left: 1px #000000 solid;

        .title {
            display: inline-block;
        }


        .icons {
            margin-top: 10px;

            .img-icon {
                height: 36px;
                width: 36px;
                margin-left: 15px;
                color: #fff;
            }

            .icon:hover {
                background: #e91a4c;
            }
        }

        .title:after {
            content: '';
            background-color: #e91a4c;
            height: 3px;
            width: 100%;
            display: block;
            margin-top: 3px;
        }

        .label-name {
            display: inline-block;
            padding: 0 5px;
        }

        .label-name-white:hover {
            color: #fff;
            background-color: #000;
        }

        .label-name-black:hover {
            color: #000;
            background-color: #fff;
        }
    }
}

.footer-bottom {
    padding: 30px 40px;
    margin-top: 40px;
    text-align: left;
    display: flex;
    justify-content: end;
    font-size: 18px;
    min-height: 100px;
    color: #fff;

    .footer-top-left {
        font-size: 24px;
        font-weight: 700;
        margin-right: auto;
        line-height: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .link-name {
            font-size: 12px;

            a {
                color: #fff;
                text-decoration: none;
                overflow-wrap: break-word;
                word-break: normal;
            }

            a:hover {
                border-bottom: 1px #fff solid;
            }
        }
    }

}

.border-black {
    border-left: 1px #000 solid;
}

.border-white {
    border-left: 1px #fff solid;
}

.color-white {
    color: #fff
}

.color-black {
    color: #000
}

.bg-black {
    background-color: #000;
}

.bg-grey {
    background-color: #202022;
}
</style>
