const animationMap = new WeakMap();
const ob = new IntersectionObserver(entries => {
	for (let entry of entries) {
		if (entry.isIntersecting) {
			const animation = animationMap.get(entry.target);
			animation.play();
			ob.unobserve(entry.target);
		}
	}
})

function isBelowViewport(el) {
	const rect = el.getBoundingClientRect();
	return rect.top > window.innerHeight;
}

/**
 * params
 * direction: 动画方向 X、Y、XY  默认Y
 * 动画方向距离 distance_x、distance_y（数组[0]开始距离，数组[1]结束距离）
 * duration: 延时  默认1000ms
 * opacity: 透明度变化 （数组【0】开始透明度、数组【1】结束透明度）
 */

export default {
	inserted(el, binding) {
		let DISTANCE_Y = ['150px', '0px'];
		let DISTANCE_X = ['-100%', '0px'];
		let DURATION = 1000;
		let OPACITY = [0, 1];
		let startTransform = {}, endTransform = {};
		if (binding && binding.value) {
			let option = binding.value
			if (option.distance_x) DISTANCE_X = option.distance_x;
			if (option.distance_y) DISTANCE_Y = option.distance_y;
			if (option.direction && option.direction === 'X') {
				startTransform['transform'] = `translateX(${DISTANCE_X[0]})`;
				endTransform['transform'] = `translateX(${DISTANCE_X[1]})`;
			} else if (option.direction && option.direction === 'XY') {
				startTransform['transform'] = `translateX(${DISTANCE_X[0]}) translateY(${DISTANCE_Y[0]})`;
				endTransform['transform'] = `translateX(${DISTANCE_X[1]}) translateY(${DISTANCE_Y[1]})`;
			} else {
				startTransform['transform'] = `translateY(${DISTANCE_Y[0]})`;
				endTransform['transform'] = `translateY(${DISTANCE_Y[1]})`;
			}

			// 透明度
			if (option.opacity) {
				OPACITY = option.opacity;
			}

			//延时
			if (option.duration) {
				DURATION = option.duration;
			}
		} else {
			startTransform['transform'] = `translateY(${DISTANCE_Y[0]})`;
			endTransform['transform'] = `translateY(${DISTANCE_Y[1]})`;
		}

		startTransform['opacity'] = OPACITY[0];
		endTransform['opacity'] = OPACITY[1];

		if (!isBelowViewport(el)) {
			return;
		}
		const animation = el.animate([
			startTransform,
			endTransform
		], {
			duration: DURATION,
			easing: 'ease'
		});
		animation.pause()
		animationMap.set(el, animation);
		ob.observe(el)
	},
	unmounted(el) {
		ob.unobserve(el)
	}
};