// noinspection JSCheckFunctionSignatures

const metaTitle= 'Vita Student维塔学舍-英国学生公寓租房网站';
const metaKeywords= 'Vita Student, 维塔学舍,英国留学租房,英国留学住宿,英国租房子,英国租房网站,英国学生公寓，英国学生住宿';
const metaDescription= 'Vita Student维塔学舍，源自英国的高端体验式学生公寓，以其优质英国学生留学租房服务和硬件配套屡次荣登专业设计网站Design Cruial主办的英国豪华学生公寓排行榜。便利的地理位置，优越的住宿环境，健全的公共设施，一站式24小时服务，超高的英国留学学生租房推荐率，物超所值。';

import Vue from 'vue'
import App from './App.vue'
import VueI18n from "vue-i18n";
import router from './router';
import store from "./store";
import 'bootstrap/dist/css/bootstrap.css';

import "assets/iconfont/iconfont.css";

Vue.config.productionTip = false;

// 完整引入使用element
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);

// 引入less
import less from 'less'

Vue.use(less);
Vue.use(VueI18n);

const i18n = new VueI18n({
	locale: (function () {
		if (localStorage.getItem("lang")) {
			return localStorage.getItem("lang");
		}
		return "cn";
	})(),
	messages: {
		en: require("./assets/language/en"), //英文包
		cn: require("./assets/language/cn"), //中文包
	},
});


// 引入 vue-awesome-swiper
import VueAwesomeSwiper from 'vue-awesome-swiper'

//引入 vue-awesome-swiper 样式
import 'swiper/dist/css/swiper.css'

Vue.use(VueAwesomeSwiper, /* { 全局组件的默认选项 } */)


new Vue({
	store,
	router,
	i18n,
	render: h => h(App)
}).$mount('#app');

import vSlideIn from './directives/vSlideIn';

Vue.directive('slide-in', vSlideIn);

// 全局路由方法
Vue.prototype.$goto = function (url, newTab = false) {
	if (newTab) {
		// 在新标签页中打开页面
		window.open(url, '_blank');
	} else {
		// 在当前标签页中打开页面
		this.$router.push("/" + i18n.locale + url);
	}
};

Vue.prototype.$handleHref = function (url, open = true) {
	if (open) {
		// 在新标签页中打开页面
		window.open(url, '_blank');
	} else {
		window.location.href = url
	}
};

// 全局路由方法
Vue.prototype.$initMeta = function (title, keywords, description) {
	// 获取或创建meta标签
	let titleEl = document.querySelector('title');
	let titleMeta = document.querySelector('meta[name="title"]');
	if (!titleMeta) {
		titleMeta = document.createElement('meta');
		titleMeta.setAttribute('name', 'title');
		document.head.appendChild(titleMeta);
	}
	if (!titleEl) {
		titleEl = document.createElement('title');
		document.head.appendChild(titleEl);
	}
	if(title) {
		titleEl.innerHTML = title;
		titleMeta.setAttribute('content', title);
	} else {
		titleEl.innerHTML = metaTitle;
		titleMeta.setAttribute('content', metaTitle);
	}

	let keywordsMeta = document.querySelector('meta[name="keywords"]');
	if (!keywordsMeta) {
		keywordsMeta = document.createElement('meta');
		keywordsMeta.setAttribute('name', 'keywords');
		document.head.appendChild(keywordsMeta);
	}
	if(keywords) {
		keywordsMeta.setAttribute('content', keywords);
	} else {
		keywordsMeta.setAttribute('content', metaKeywords);
	}


	let descriptionMeta = document.querySelector('meta[name="description"]');
	if (!descriptionMeta) {
		descriptionMeta = document.createElement('meta');
		descriptionMeta.setAttribute('name', 'description');
		document.head.appendChild(descriptionMeta);
	}
	if(description) {
		descriptionMeta.setAttribute('content', description);
	} else {
		descriptionMeta.setAttribute('content', metaDescription);
	}
};

if(process.env.NODE_ENV == 'production') {
	router.afterEach((to, from, next ) => {
		setTimeout(()=>{
			var _hmt = _hmt || [];
			(function() {
			  var hm = document.createElement("script");
			  hm.src = "https://hm.baidu.com/hm.js?34b3ad839c15d0de72fe2b3b6adb2844";
			  var s = document.getElementsByTagName("script")[0]; 
			  s.parentNode.insertBefore(hm, s);
			})();
		},0);
	});
}





