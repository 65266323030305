import Vue from 'vue'
import Router from 'vue-router';
import i18n from "vue-i18n";

Vue.use(Router);

const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/', //
            redirect: '/cn',
            component: {
                render(c) { return c('router-view'); }
            }
        },
        {
            name: '404',
            path: '/404',
            component: () => import('../views/landing-page/index'),
        },
        {
            path: '/:lang', // 语言前缀作为参数
            component: {
                render(c) { return c('router-view'); }
            },
            children: [
                {
                    path: '/',
                    component: () => import('../views/home/index'),
                    meta: { title: '首页', index: 1 }
                },
                {
                    path: 'cities',
                    component: () => import('../views/cities/index')
                },
                {
                    path: 'cities/:slug',
                    component: () => import('../views/cities/city')
                },
                {
                    path: 'cities/:slug/:apartmentSlug',
                    component: () => import('../views/cities/apartments/apartment')
                },
                {
                    path: 'blog',
                    component: () => import('../views/blog/index'),
                    meta: { title: 'Blog', index: 300 }
                },
                {
                    path: 'mobile',
                    component: () => import('../views/home/mobile'),
                    meta: { title: 'Blog', index: 301 }
                },
                {
                    path: 'contact-us',
                    component: () => import('../views/contact-us/index'),
                    meta: { title: 'Contact Us', index: 400 }
                },
                {
                    path: 'faqs',
                    component: () => import('../views/faqs/index'),
                    meta: { title: 'FAQs', index: 600 }
                },
                {
                    path: 'international-students',
                    component: () => import('../views/international-students/index'),
                    meta: { title: 'International Students', index: 1 }
                },
                {
                    path: 'life-at-vita',
                    component: () => import('../views/navs/index'),
                    meta: { title: 'Life At Vita', index: 700 }
                },
                {
                    path: 'refer-your-friends',
                    component: () => import('../views/navs/refer'),
                    meta: { title: 'REFER YOUR FRIENDS.', index: 701 }
                },
                {
                    path: 'my-account',
                    component: () => import('../views/my-account/index'),
                    meta: { title: 'My Account', index: 800 }
                },
                {
                    path: 'parents',
                    component: () => import('../views/parents/index'),
                    meta: { title: 'Parents', index: 900 }
                },
                {
                    path: 'find-your-room',
                    component: () => import('../views/landing-page/index'),
                    meta: { title: 'Parents', index: 900 }
                },
                {
                    path: 'beyond-ambition',
                    component: () => import('../views/beyond-ambition/index'),
                    meta: { title: 'Beyond ambition', index: 1 }
                },
                { path: "*", redirect: "/404", hidden: true }
            ]
        }

    ]
});

// 在导航守卫中修改路由路径
router.beforeEach((to, from, next) => {
    const lang = to.params.lang || 'cn'; // 默认语言为英语
    if (i18n.locale !== lang) {
        i18n.locale = lang; // 设置i18n的当前语言
        localStorage.setItem("lang", lang);
    }
    if (lang !== to.params.lang) {
        const newRoute = { ...to, params: { ...to.params, lang } };
        next(newRoute);
    } else {
        // 遍历动态参数列表，检查是否有参数发生变化
        // const paramsChanged = Object.keys(to.params).some(param => {
        //     return to.params[param] !== from.params[param];
        // });
        // if (paramsChanged) {
        // }
        next();
    }
});


export default router;
