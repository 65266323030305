import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
import {setToken, getToken} from '@/utils/token'
import {getCitiesAPI} from '@/apis/common'

const store = new Vuex.Store({
	state: {
		cities: JSON.parse(setToken("cities") || '[]')
	},
	mutations: {
		setCities(state, cities) {
			setToken("cities", JSON.stringify(cities));
			state.cities = cities;
		}
	},
	getters: {
		getCities: (state) => {
			state.cities = getToken("cities");
			return state.cities;
		}
	},
	actions: {
		updateCities(context, value) {
			context.commit("setCities", value);
		}
	},
	modules: {},
});

export default store;
