import axios from 'axios'
import {getToken} from '@/utils/token'
import {Message} from 'element-ui'

// 创建axios实例
const instance = axios.create({
	baseURL: process.env.VUE_APP_BASE_API,
	timeout: 3 * 1000, // 请求超时时间
})

// 全局请求拦截，发起请求之前执行
instance.interceptors.request.use(
	function (config) {
		if (getToken()) {
			config.headers.Authorization = 'Bearer' + ' ' + getToken();
			// config.headers.AppVersion = APP_VERSION;
		}
		if (config.method === 'post') {
			config.data['forPc'] = "true";
		}
		else if (config.method === 'get') {
			if (config.params) {
				config.params['forPc'] = "true";
			} else {
				config.params = {'forPc': "true"};
			}
		}
		return config;
	},
	function (error) {
		return Promise.reject(error);
	}
);

instance.interceptors.response.use((response) => {
//返回码以自己的为定，如果没有安装elementui就换成自己的提示
	let {code, msg} = response.data
	if (code != 200) {
		Message({message: msg || 'error', type: 'warning'})
	}
	return response;
}, (error) => {
	return Promise.reject(error)
})

export function get(url, params) {
	return instance.get(url, {
		params,
	});
}

export const post = (url, data) => instance.post(url, data);

export const put = (url, data) => instance.put(url, data);

export const del = (url) => instance.delete(url);

export default instance;