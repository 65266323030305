export const common = require('./en/common.json')
export const header = require('./en/header.json')
export const home = require('./en/home.json')
export const footer = require('./en/footer.json')
export const blog = require('./en/blog.json')
export const cities = require('./en/cities.json')
export const apartment = require('./en/apartment.json')
export const city = require('./en/city.json')
export const contactUs = require('./en/contact-us.json')
export const faqs = require('./en/faqs.json')
export const parents = require('./en/parents.json')
export const lifeAtVita = require('./en/life-at-vita.json')
export const internationalStudents = require('./en/international-students.json')