<template>
    <div class="container-fluid" style="padding: 0 45px">
        <div class="header" :style="{backgroundColor: color[1], color: color[0]}">
            <div class="title menu z-index-menu cursor font-size-18" :style="{color: dialogVisibleMenu ? color[1] : color[0]}"
                 @click="handleSelect('/')">
                <img height="24" style="margin: -5px 0 0 -3px" :src="titleImg" alt="">
            </div>
            <div class="menu-list margin-left-auto">
                <div class="menu menu-line cursor" :class="dialogVisibleMenuCities ? 'menu-active': ''"
                     @click="dialogVisibleMenuCities = true">{{ $t('header.cities') }}
                </div>
                <div class="menu menu-line cursor" @click="handleSelect('/faqs')">{{ $t('header.faqs') }}</div>
                <div class="menu cursor d-flex align-items-center">
                    <el-dropdown placement="bottom" @command="handleCommand">
						<span class="el-dropdown-link d-flex align-items-center">
							<img class="img-icon" :src="languageIcon[language]" alt="">
					        <i class="el-icon-arrow-down el-icon--right"></i>
						</span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="en" class="el-dropdown-link d-flex align-items-center">
                                <img style="width: 25px;height: 25px;" :src="languageIcon['en']" alt="">
                                <div class="ms-1">English</div>
                            </el-dropdown-item>
                            <el-dropdown-item command="cn" class="el-dropdown-link d-flex align-items-center">
                                <img style="width: 25px;height: 25px;" :src="languageIcon['cn']" alt="">
                                <div class="ms-1">中文</div>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
                <div class="menu z-index-menu cursor">
<!--                    <span class="icon iconfont" v-if="!dialogVisibleMenu && !dialogVisibleMenuCities"-->
<!--                          @click="dialogVisibleMenu = true" :style="{color: color[0]}">&#xe600;</span>-->
                    <img height="23"
                         @click="dialogVisibleMenu = true"
                         :src="menuImg"
                         v-if="!dialogVisibleMenu && !dialogVisibleMenuCities" alt="">
                    <div class="menu-list-close text-center" v-if="dialogVisibleMenu"
                         @click="dialogVisibleMenu = false"> ×
                    </div>
                    <div class="menu-list-close text-center" v-if="dialogVisibleMenuCities"
                         @click="dialogVisibleMenuCities = false"> ×
                    </div>
                </div>

                <div class="menu z-index-menu find-btn cursor"
                     @click="$goto('/find-your-room')">{{ $t('header.findYourRoom') }}</div>

            </div>
        </div>
        <div class="menu-dialog d-flex align-items-center justify-content-center" v-if="dialogVisibleMenu">
            <div class="items">
                <div class="item cursor" v-for="(menu, index) of menus" @click="handleLink(menu.router)" :key="index">
                    <div class="label">
                        <div class="label-line"></div>
                    </div>
                    <div class="label-name">
                        <div class="d-flex align-items-center">
                            {{ menu.name }}
                            <i class="el-icon-arrow-right el-icon--right"></i>
                        </div>
                        <div class="label_underline"></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="menu-cities-dialog" v-if="dialogVisibleMenuCities">
            <div class="container p-t-54 p-b-10 mt-0">
                <div class="row">
                    <div class="col-2">
                        <div class="vita-cities-title">
                            <div class="cities-title">{{ $t('header.cities') }}</div>
                        </div>
                    </div>
                    <div class="col-9">
                        <div class="tab-header">
                            <div class="tab"
                                 :class="currentTab === tab.country ? 'tab-active' : ''"
                                 @click="currentTab = tab.country"
                                 v-for="(tab, index) of cityTab"
                                :key="index">
                                {{ tab.country }}
                            </div>
                        </div>
                        <div class="tab-content"
                             v-for="(tab, index) of cityTab" v-if="currentTab === tab.country"
                             :key="index"
                             style="height: calc(100vh - 230px);">
                            <div class="city" v-for="(city, index) of tab.cities" :key="index">
                                <div class="m-b-9 city-name font-weight-700 d-flex align-items-center cursor"
                                     @click="handleLink(`/cities/${city.slug}`)">
                                    <span class="city-label text-uppercase">{{ city.name }}</span>
                                    <i class="el-icon-arrow-right el-icon--right"></i>
                                </div>
                                <div class="apartment m-b-9 cursor"
                                     @click="handleLink(`/cities/${city.slug}/${apartment.slug}`)"
                                     v-for="(apartment, index) of city.apartments" :key="index">
                                    <div class="label" v-if="apartment.label">{{ apartment.label }}</div>
                                    <div class="apartment-name">{{ apartment.name }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bottom-area"></div>
        </div>
    </div>
</template>

<script>

export default {
    name: "MenuBar",
    data() {
        return {
            currentTab: null,
            dialogVisibleMenuCities: false,
            dialogVisibleMenu: false,
            activeIndex2: '/',
            languageIcon: {
                en: require('assets/images/icon/en.svg'),
                cn: require('assets/images/icon/cn.png')
            },
            language: this.$i18n.locale
        };
    },
    props: {
        colorType: {
            type: Number,
            default: 0
        },
        cities: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        color: function () {
            if (this.colorType === 0) return ['#000', '#fff'];
            else return ['#fff', '#000'];
        },
        titleImg: function () {
            if (this.colorType === 0) return require('assets/images/icon/title-black.png');
            else return require('assets/images/icon/title-white.png');
        },
        menuImg: function () {
            if (this.colorType === 0) return require('assets/images/icon/menu-black.png');
            else return require('assets/images/icon/menu-white.png');
        },
        cityTab: function () {
            let isCn = this.$i18n.locale === 'cn'
            const cities = []
            this.cities.forEach(rest => {
                if (rest.slug === 'barcelona') rest.country = isCn ? '西班牙' : 'Spain'
                else rest.country = isCn ? '英国' : 'United Kingdom'
                let city = {
                    id: rest.id,
                    img: null,
                    slug: rest.slug,
                    name: isCn ? rest.name : rest.enName,
                    country: rest.country
                };
                let apartments = []
                if (rest.apartments && rest.apartments.length > 0) {

                    rest.apartments.forEach(item => {
                        let label = null
                        if (~['poblenou', 'copper-towers', 'bruce-street', 'iona-street'].indexOf(item.slug)) {
                            label = 'New'
                        }
                        apartments.push({
                            id: item.id,
                            slug: item.slug,
                            name: isCn ? item.cnName : item.name,
                            label: label
                        })
                    })
                }
                city['apartments'] = apartments
                cities.push(city)
            })
            const cityTab = Object.values(cities.reduce((acc, {country, ...rest}) => {
                if (!acc[country]) {
                    acc[country] = {country, cities: []};
                }
                acc[country].cities.push(rest);
                return acc;
            }, {}));
            if (cityTab.length > 0) this.currentTab = cityTab[0].country
            return cityTab;
        },
        menus: function () {
            return [
                {name: this.$t('header.cities'), router: '/cities'},
                // {name: this.$t('header.contactUs'), router: '/contact-us'},
                {name: this.$t('header.lifeAtVita'), router: '/life-at-vita'},
                {name: this.$t('header.parents'), router: '/parents'},
                {name: this.$t('header.internationalStudents'), router: '/international-students'}
                // {name: this.$t('header.blog'), router: '/blog'}
            ]
        },
    },
    watch: {
        dialogVisibleMenuCities(n, o) {
            this.handleOverflowY(this.dialogVisibleMenuCities);
        },
        dialogVisibleMenu(n, o) {
            this.handleOverflowY(this.dialogVisibleMenu);
        },
    },
    methods: {
        handleSelect(path) {
            this.dialogVisibleMenuCities = false;
            this.$router.push('/' + this.$i18n.locale + path);
        },
        handleOverflowY(v) {
            if (v) document.body.style.overflowY = 'hidden';
            else document.body.style.overflowY = 'auto';
        },
        handleCommand(command) {
            // this.$message('click on item ' + command);
            this.$i18n.locale = command;
            localStorage.setItem("lang", command);
            this.language = command;
            this.$router.push({params: {lang: command}});
        },
        handleLink(router) {
            this.$router.push('/' + this.$i18n.locale + router);
            this.dialogVisibleMenu = false;
            this.dialogVisibleMenuCities = false;
        }
    }
}
</script>

<style lang="less" scoped>
/* 菜单栏 */
.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;

    .title {
        font-weight: 500;
        //text-shadow: 1px 0 0 #000000;
        font-size: 22px;
        position: relative;
        letter-spacing: 1.4px;
        z-index: 21;
        font-family: Radikal Light,sans-serif;

    }

    .menu-list {
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .menu {
            margin: 0 0 0 30px;

            .iconfont {
                font-size: 20px;
            }

            .menu-list-close {
                width: 40px;
                height: 40px;
                line-height: 40px;
                font-size: 42px;
                color: #fff;
                background-color: #e91a4c;
            }
        }

        .menu-line:hover {
            border-bottom: 2px #e91a4c solid;
        }

        .menu-active {
            color: #fff;
            padding: 0 10px;
            line-height: 50px;
            background-color: #000;
        }

        .menu-line:hover {
            border-bottom: 2px #000 solid !important;
        }

        .margin-left-auto {
            margin-left: auto;
        }
    }

    .img-icon {
        width: 22px;
        height: 22px;
        border-radius: 50%;
    }

    .find-btn {
        background: #e91a4c;
        padding: 4px 15px 0 15px;
        color: #fff;
        height: 30px;
        line-height: 26px;
        border-radius: 15px;
        font-size: 16px;
        letter-spacing: .5px;
        font-weight: 900;
        font-family: Trade Gothic LT Std Condensed, sans-serif;
    }

    .find-btn:hover {
        background: #32373c;
    }
}

.z-index-menu {
    position: relative;
    z-index: 21;
}

.menu-dialog {
    position: fixed;
    width: 100vw;
    height: 100vh;
    color: #fff;
    background: #000;
    z-index: 20;
    top: 0;
    left: 0;

    .item {
        border-top: 1px solid #333;
        font-size: 24px;
        width: 60vw;
        min-width: 500px;
        font-weight: 700;
        line-height: 1.1;
        display: flex;
        align-items: center;

        .label {
            width: 150px;
            height: 60px;

            .label-line {
                width: 100px;
                height: 0;
                background-color: #e91a4c;
            }
        }

        .label_underline {
            width: 0;
            height: 3px;
            background-color: #e91a4c;
        }

        .el-icon-arrow-right {
            display: none;
        }

    }

    .item:hover {
        color: #e91a4c;
        animation-duration: .5s;
        animation-fill-mode: forwards;

        .label-line {
            animation-name: animationLine;
            animation-duration: .15s;
            animation-fill-mode: forwards
        }

        .label_underline {
            animation-name: animationUnderLine;
            animation-duration: .5s;
            animation-fill-mode: forwards
        }

        .el-icon-arrow-right {
            display: block;
            animation-duration: .5s;
            animation-fill-mode: forwards
        }
    }

    @keyframes animationLine {
        0% {
            height: 0;
        }
        100% {
            height: 6px;
        }
    }

    @keyframes animationUnderLine {
        0% {
            width: 0;
        }
        100% {
            width: 100%;
        }
    }
}

.menu-cities-dialog {
    overflow-y: auto; /* 允许垂直滚动 */
    padding: 0 60px;
    position: fixed;
    width: 100vw;
    min-height: calc(100vh - 50px);
    color: #fff;
    background: #000;
    z-index: 20;
    top: 50px;
    left: 0;
    font-family: Trade Gothic LT Std Condensed, sans-serif;

    .vita-cities-title {
        display: flex;
        align-items: end;
        justify-content: center;
        width: 100%;
        min-height: 1px;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;

        .cities-title {
            position: absolute;
            font-family: Trade Gothic LT Std Condensed, sans-serif;
            text-transform: uppercase;
            font-size: 72px;
            line-height: 1;
            font-weight: 700;
            color: #fff;
            bottom: 150px;
            transform-origin: 50%;
            transform: rotate(-90deg);
        }

        .cities-title:before {
            width: 6px;
            height: 60px;
            display: block;
            position: absolute;
            background-color: #e91a4c;
            content: "";
            transform: translateX(-20px) translateY(-1px);
        }
    }

    .tab-header {
        display: flex;
        padding-top: 30px;
        border-bottom: 1px rgba(112, 112, 112, .5) solid;

        .tab {
            font-size: 45px;
            font-weight: 700;
            color: #5f5f5f;
            margin-right: 60px;
            text-transform: uppercase;
        }

        .tab:before {
            content: "";
            display: block;
            background-color: #5f5f5f;
            position: relative;
            height: 6px;
            width: 45px;
        }


        .tab:hover, .tab-active {
            transition: all 1s ease;
            color: #fff;
        }

        .tab:hover:before, .tab-active:before {
            //width: 80%;
            content: "";
            display: block;
            background-color: #e91a4c;
            position: relative;
            height: 6px;
        }
    }

    .tab-content {
        border-bottom: 15px white solid;
        padding-top: 20px;
        //padding-bottom: 60px;
        column-count: 4; /* 设置列数 */
        column-gap: 20px; /* 设置列之间的间距 */
        overflow-y: auto; /* 如果内容超过容器高度，显示纵向滚动条 */


        .city {
            font-size: 24px;
            padding-top: 10px;
            display: inline-block;
            vertical-align: top;
            width: 100%;

            .el-icon-arrow-right {
                display: none;
            }

            .city-name:hover, .apartment:hover {
                color: #e91a4c;

                .city-label, .apartment-name {
                    text-decoration: underline #e91a4c;
                }

                .el-icon-arrow-right {
                    display: block;
                    animation-duration: .5s;
                    animation-fill-mode: forwards
                }
            }

            .apartment {
                display: flex;
                align-items: center;
                font-size: 16px;
                //padding-top: 5px;
                font-family: Radikal Light, sans-serif !important;

                .label {
                    background-color: #e91a4c;
                    padding: 0 5px;
                    border-radius: 1px;
                    font-weight: bold;
                    font-family: Trade Gothic LT Std Condensed, sans-serif;
                    text-transform: uppercase;
                    font-size: 12px;
                    line-height: 1.2;
                    padding-top: 2px;
                    margin-right: 3px;
                    color: #fff;

                }
            }
        }
    }
}


::-webkit-scrollbar {
    width: 0.2rem;
    height: 0.2rem;
    background-color: rgba(212, 212, 212, 0);
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 1px rgba(119, 119, 119, 0.2);
    border-radius: 10px;
    background-color: rgba(119, 119, 119, 0.2);
}

::-webkit-scrollbar-thumb {
    border-radius: 2px;
    -webkit-box-shadow: inset 0 0 6px #eee;
    outline: 1px solid rgba(119, 119, 119, 0.2);
}

::-webkit-scrollbar {
    width: 0.1em;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #333;
}

</style>
