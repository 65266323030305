<template>
    <div>
        <MenuBar v-if="showNav" :cities="cities" :style="{ backgroundColor: backgroundColor }" :colorType="colorType"></MenuBar>
        <transition :name="transitionName">
            <router-view :key="fullPath"></router-view>
        </transition>
        <Footer v-if="showNav" :cities="cities" :style="{ backgroundColor: backgroundColor }" :colorType="colorType"></Footer>
    </div>
</template>

<script>
import MenuBar from './MenuBar.vue';
import Footer from './Footer.vue';

import {getCitiesAPI} from '@/apis/common'

export default {
    name: "index",
    components: {
        MenuBar,
        Footer
    },
    data() {
        return {
            cities: [],
            transitionName: '',
            backgroundColor: '',
            colorType: 0
        };
    },
    computed: {
        fullPath: function () {
            return this.$route.fullPath.substring(2, this.$route.fullPath.length);
        },
        showNav() {
            if(window.location.href.match(/mobile/i)) {
                return false;
            }
            return true;
        }
    },
    created() {
        this.getCities();
    },
    updated() {
        this.setBgColor(this.$route.path);
    },
    watch: {
        $route(to, from) {
            if (to.meta.index > from.meta.index) {
                this.transitionName = "slide-left";
            } else {
                this.transitionName = "slide-right";
            }
        },
    },
    methods: {
        async getCities() {
            if (this.$store.state.cities.length > 0) {
                this.cities = this.$store.state.cities;
            } else {
                await getCitiesAPI({showApartments: 1}).then(res => {
                    if (res.status === 200) {
                        this.cities = res.data.data
                        this.$store.commit('setCities', this.cities)
                    }
                })
            }
        },
        setBgColor(path) {
            const specialBackgroundRoutes = ['', '/', '/faqs', '/parents', '/international-students', '/cities/:slug/:apartmentSlug'];
            const matchedRoute = specialBackgroundRoutes.some((route) => {
                route = '/' + this.$i18n.locale + route;
                const regex = new RegExp(`^${route.replace(/:\w+/g, '.*')}$`);
                return regex.test(path);
            });
            if (matchedRoute) {
                this.backgroundColor = '#fff';
                document.body.style.backgroundColor = '#fff';
                this.colorType = 0;
            } else {
                this.backgroundColor = '#000';
                document.body.style.backgroundColor = '#000';
                this.colorType = 1;
            }

            const metaRoutes = ['/international-students', '/cities/:slug/:apartmentSlug'];
            const metaMatchedRoute = metaRoutes.some((route) => {
                route = '/' + this.$i18n.locale + route;
                const regex = new RegExp(`^${route.replace(/:\w+/g, '.*')}$`);
                return regex.test(path);
            });
            if(!metaMatchedRoute){
                this.$initMeta(null, null, null);
            }
        }
    }
}
</script>

<style lang="less" scoped>
.slide-top-enter-active,
.slide-top-leave-active,
.slide-right-enter-active,
.slide-right-leave-active,
.slide-bottom-enter-active,
.slide-bottom-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
    will-change: transform;
    //transition: all 500ms;
    position: fixed;
}

.slide-right-enter {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
}

.slide-right-leave-active {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
}

.slide-left-enter {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
}

.slide-left-leave-active {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
}

body {
    margin: 0 !important;
    overflow-x: hidden;
}


</style>
